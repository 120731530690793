/* ------------ Colors ------------ */

a {
  text-decoration: none !important;
  color: #727272 !important;
}

/* ------------ Aligning ------------ */

.alignItemsCenter {
  align-items: center !important;
}

.space-between {
  justify-content: space-between !important;
}

/* ------------ Components ------------ */

.appWrapper {
  padding-left: 20px !important;
  padding-right: 20px !important;
  max-width: 600px !important;
  margin: 0 auto !important;
}

.footerContainer {
  align-items: center !important;
  justify-content: center !important;
  margin-top: 35px !important;
  padding-bottom: 15px !important;
}

.footerLogo {
  width: auto; 
  height: 15px; 
  margin-left: 5px; 
  margin-top: 2px;
  pointer-events: none !important;
}

.footerText {
  font-size: 0.75rem !important;
  margin-top: 4px !important;
}

/* ------------ Typogaraphy ------------ */

.sub-title {
  font-size: 1.3rem !important;
  font-weight: 600 !important;
}

.content {
  font-size: 0.9rem !important;
  font-weight: 400 !important;
}

.bold {
  font-weight: 600 !important;
}

.italic {
  font-style: italic !important;
}

.center {
  text-align: center !important;
}